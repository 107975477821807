class Base
{

 changeCycle()
 {
  $('.changeCycle').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas activar este ciclo?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo este será el ciclo que se tomará en cuenta para el sorteo.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/cycle/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>El ciclo seleccionado ha sido activado</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha activado el ciclo, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha activado el ciclo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha activado el ciclo porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha activado el ciclo porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha activado el ciclo, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha activado el ciclo, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha activado el ciclo porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El ciclo seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 detectBrowser()
 {
  let getBrowserInfo = function()
  {
   let ua= navigator.userAgent, tem,
   M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

   if(/trident/i.test(M[1]))
   {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE '+(tem[1] || '');
   }

   if(M[1]=== 'Chrome')
   {
    tem= ua.match(/\b(OPR|Edge)\/(\d+)/);

    if(tem!= null)
     return tem.slice(1).join(' ').replace('OPR', 'Opera');
   }

   M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];

   if((tem= ua.match(/version\/(\d+)/i))!= null)
    M.splice(1, 1, tem[1]);

   return M.join(' ');
  };

  if(getBrowserInfo().substr(0,2)=='IE'){
   alert('¡Atención!, Esta usando Internet Explorer, este navegador no es compatible con algunas funciones del sitio web, por favor utilice otro navegador para una mejor experiencia, preferiblemente Google Chrome o Firefox');
  }
 }

 hideModal()
 {
  $('#btnRegister').click(function()
  {
   $('#modalHome').modal('hide');
  });
 }

 playVideoHome()
 {
  $('#modalHome').on('hide.bs.modal', function (e){
   var video = document.getElementById('myVideo');
   video.play();
  })

  $('#modalRegister').on('hide.bs.modal', function (e){
   var video = document.getElementById('myVideo');
   video.play();
  })
 }

 selectInput()
 {
  let input = $(".select_input");
  input.select2();
 }

 timer()
 {
  let url = '/session';
  let minutes = 720;

  fetch(url)
   .then(function(response){
    if(response.ok){
     return response.text();
    }
   })
   .catch(function(error)
   {
    Swal.fire({
     icon: "error",
     title: "<h6 class='text-center'>Atención</h6>",
     html: "<h5 class='text-center'>Por favor revise su conexión a internet.</h5>",
     confirmButtonColor: '#FFA500'
    })
   })
   .then(function(data)
   {
    if(data!='No logueado')
    {
     let number = 60000*minutes;

     setTimeout( function()
     {
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Su sesión ha caducado, por favor vuelva a loguearse.</h5>",
       confirmButtonColor: '#FFA500'
      })

      location.href = '/logout';
     }, number);
    }
   });
 }
}

window.onload = () => {
 let base = new Base();

 base.changeCycle();
 base.detectBrowser();
 base.hideModal();
 base.playVideoHome();
 base.selectInput();
 base.timer();
};
