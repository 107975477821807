<template>
 <div>
  <div id="bg-image">
   <a id="izq" class="arrows izquierda" @click="back()">
    <img class="img-arrows" src="img/Botones-Cocteles/Recurso 48.png">
   </a>
   <a id="der" class="arrows derecha" @click="next()">
    <img class="img-arrows" src="img/Botones-Cocteles/Recurso 49.png">
   </a>
  </div>
 </div>
</template>

<script>

 export default {
  name:'lobby',
  props:['id_padre_img'],
  data:()=>({
    bgimg:'',
    currentIndex:1,
    der:'',
    izq:'',
    maxindex:0
  }),

  created(){
    var url = BASE_URL+'/getImg';
    var data = {};
    data.id = this.id_padre_img;
    axios.post(url, data).then(response=>{
      this.imgArray = response.data;
      this.izq = document.getElementById('izq');
      this.der = document.getElementById('der');
      this.bgimg = document.getElementById('bg-image');
      this.izq.style.visibility = 'hidden';
      this.maxindex = this.imgArray.length;
      this.bgimg.style.backgroundImage = "url('" +  this.imgArray[0].ruta +"')";
    });
  },

  methods:{
    next(){
      this.bgimg.style.backgroundImage = "url('" +  this.imgArray[this.currentIndex].ruta +"')";
      this.currentIndex ++;
      this.moveImage();
    },
    back(){
      this.currentIndex --;
      this.bgimg.style.backgroundImage = "url('" +  this.imgArray[this.currentIndex].ruta +"')";
      this.moveImage();
    },
    moveImage(){
      console.log(this.currentIndex, this.maxindex)
      if(this.currentIndex == this.maxindex){
        this.der.style.visibility = 'hidden';
        this.izq.style.visibility = 'visible';
        this.currentIndex --;
      }else if(this.currentIndex == 0){
        this.izq.style.visibility = 'hidden';
        this.der.style.visibility = 'visible';
        this.currentIndex ++;
      }else{
        this.der.style.visibility = 'visible';
        this.izq.style.visibility = 'visible';
      }
    },
    showCoctel(){
      console.log('currentBrand');
      console.log(numCoctel)
    }
  }
 }
</script>
