<template>
 <div>
  <video autoplay muted loop id="myVideo">
   <source src="img/video/screensaver.mp4" type="video/mp4">Su navegador no soporta este formato de video
  </video>
  <img class="img-fluid logo" src="img/interfaz-login/logo.png">
  <button type="button" @click="stopVideo()" data-toggle="modal" data-target="#modalHome">
   <img class="img-fluid btn-login" src="img/interfaz-login/iniciar-sesion.png">
  </button>
 </div>
</template>

<script>
 export default{
  name:'home',
  methods:{
   stopVideo(){
    var video = document.getElementById('myVideo');
    video.pause();
   }
  }
 }
</script>
